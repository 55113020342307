
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: 'ModalLink',
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
})
